#cato_card {
  overflow-x: scroll;
}
.containers {
  width: 80% !important;
  margin-top: 50px !important;
}
i {
  margin: 0px auto;
}
#cat_card {
  overflow-x: scroll;
}
.add_file {
  border-color: #c4d4bc !important;
  box-shadow: none !important;
  outline: 0 none !important;
}
.category_add {
  height: 550px;
}
.category_add.show {
  display: block;
}
.cat.show {
  display: block;
}
.edit_btn {
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
@media screen and (max-width: 688px) {
  /* .table{
      overflow-x: scroll;
     } */
  .inst_card {
    overflow-x: scroll;
    width: 100% !important;
  }
  .btn {
    width: 45px !important;
  }
  .category-head {
    font-size: 29px;
    margin-left: 42px;
    margin-top: 7px;
  }
}
.add_file {
  border-color: #c4d4bc !important;
  box-shadow: none !important;
  outline: 0 none !important;
}
.category_adds {
  height: 50px;
}
.category {
  width: 100%;
  height: 150px;
  border-color: #c4d4bc;
  border-radius: 10px;
  padding: 10px 20px;
}
.category_add.show {
  display: block;
}
.cato.show {
  display: block;
}
.error_msg {
  color: red;
}
/* modal */
.eye-btn {
  color: green !important;
  border: none;
  padding: 0;
  margin: 0;
}
.del-i-btn {
  color: red !important;
  font-size: 18px;
  border: none;
  padding: 0;
  margin: 0;
}
.modal_wrappers {
  position: fixed;
  width: 100%;
  min-height: 100%;
  background: rgba(30, 30, 30, 0.583);
  backdrop-filter: saturate(100%) blur(2px);
  z-index: 100;
}
.modal_wrappers > .modal_containers {
  position: absolute;
  background-color: #fff;
  padding: 20px 30px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: none;
  transition: all 0.5s ease-in-out;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.edit-cat {
  width: 50%;
  margin: 0px auto;
}

@media screen and (max-width: 600px) {
  .containers {
    width: 100% !important;
  }
  .mode-cat {
    width: 65% !important;
    margin: 0px auto !important;
    margin-left: 35px !important;
  }
  .edit_category {
    padding-left: 19px;
    width: 109%;
  }
}

@media screen and (max-width: 873px) {
  .category-head {
    margin-top: 10px;
  }
}
