/* Modal CSS */
.sched_btn {
  position: absolute;
  width: 100px;
  outline: none;
  border: none;
  background-color: #8c9886;
  align-items: center;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  height: 30px;
  border-radius: 5px;
  right: 10px;
  bottom: -5px;
}

.schedule_card {
  overflow-x: scroll;
}
.schedule_card::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.schedule_card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #95a28f;
}
.schedule_card::-webkit-scrollbar-thumb {
  background-color: #95a28f;
  outline: #606b5b;
}
.date_schedule_card {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.sched_btn:hover {
  background-color: #606b5b;
  color: white;
  transition: all 0.2s ease-in-out;
}

.ant-picker {
  width: 100% !important;
  border-color: transparent;
}
.ant-picker-focused {
  border-color: transparent;
  box-shadow: 0 0 0 transparent !important;
}
.duration {
  border: none !important;
}
#schedule_modal {
  min-width: 35vw !important;
  top: 50%;
}
.left {
  float: left;
}
.ryt {
  float: right !important;
  font-size: 18px;
}
.scn-btn {
  position: relative;
  display: block;
  width: 50%;
  height: 50px;
  font-size: 20px;
  background-color: #252525;
  color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  z-index: 1;
  overflow: hidden;
  outline: none;
  border: none;
  transition: all 0.2s linear;
}

.scn-btn::after,
.scn-btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.scn-btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: #c6d6bf;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.scn-btn::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.scn-btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.scn-btn:hover {
  color: #252525;
}

.add_schedule {
  float: right;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  color: #fff;
  box-shadow: 0 0 0 transparent !important;
  border-radius: 50%;
  background-color: #3cb371;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
.add_schedule:hover {
  background-color: #768271;
}
.add_schedule:active {
  box-shadow: 0 2px 3px #747474;
}
.schedule_card {
  padding: 20px 20px 10px 20px;
}
.delete_schedule {
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  transition: all 0.2s ease-in-out;
  background-color: #d53343;
  color: #fff;
  margin-left: auto;
  margin-right: 0;
}
.view-btn {
  display: block;
  border: none;
  outline: none;
  background-color: #0072bb;
  color: #fff;
  margin: 0 auto;
  border-radius: 3px;
  padding: 5px 10px;
  transition: all 0.2s ease-in-out;
}
.view-btn:hover {
  background-color: #0466a3;
}

#approval_modal {
  top: 5%;
  width: 70%;
}
.approve_card {
  width: 40%;
  padding: 10px;
}
.approve_card > h4 {
  font-size: 24px;
}
.approve_card h4 > span {
  font-weight: 400;
}

.session_tile_head {
  background-color: #95a28f !important;
}
.action_btn.approve {
  background-color: #198754;
  height: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  color: white;
  width: 100px;
  margin-left: 25%;
  text-align: center;
}
.first_Con {
  margin-left: 3% !important;
  margin-bottom: 50px !important;
}
.action_btn.deny {
  background-color: #d53343;
  height: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  color: white;
  width: 100px;
  margin-left: 25%;
  text-align: center;
}
.schedule_card_ {
  width: 30%;
  padding: 20px;
}
.schedule_card > p,
.schedule_card_ > p {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 0;
}
.schedule_card p > span,
.schedule_card_ p > span {
  font-weight: 400;
  padding-left: 5px;
}
.schedule_details_card {
  overflow-x: hidden !important;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.icon_div {
  margin-top: 5px;
  font-size: 15px;
  margin-bottom: 10px;
}
.icon_div span {
  margin-left: 10px;
}

/* New Styles */

:where(.css-dev-only-do-not-override-acm2ia).ant-picker
  .ant-picker-input
  > input {
  font-size: 16px !important;
}
.details::placeholder {
  color: rgb(205, 207, 207);
  font-weight: 300;
}
.headsch {
  font-size: 18px;
}

.week-days {
  display: flex;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.duration-card {
  overflow: hidden;
  border-color: #e2e5e0;
  margin-bottom: 50px !important;
  width: 350px;
}
.day {
  width: 30px;
  height: 30px;
  text-align: center;
  font-weight: 500;
  border-radius: 50%;
  border: 1px solid #95a28f;
  margin: 7px;
  padding: 0 !important;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.day.select,
.day:hover {
  background-color: #95a28f;
  color: white;
}

.form-check-input {
  /* border-radius: 50% !important; */
  box-shadow: 0 0 0 transparent !important;
  margin-right: 10px;
}
.form-check-input:checked {
  background-color: #95a28f;
  outline: none;
  border: none;
  box-shadow: 0 0 0 transparent !important;
}
.form-check-input:checked:hover {
  text-decoration: none;
  border: none;
}

.duration-card::-webkit-scrollbar {
  height: 7px;
  width: 5px;
}

.duration-card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #95a28f;
}

.duration-card::-webkit-scrollbar-thumb {
  background-color: #95a28f;
  outline: 1px solid #95a28f;
}

.schedule_switch {
  margin-left: 10px;
}
.form-switch .form-check-input:focus,
.form-switch .form-check-input:active {
  color: #252525 !important;
}
.ant-btn-primary {
  background-color: #95a28f;
}
.ant-picker-now-btn:hover {
  color: #606b5b;
}
.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background-color: #606b5b !important;
}
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: #95a28f7a;
}
.ant-picker-now-btn {
  color: #95a28f;
  text-decoration: none;
}
/* *************-----schedule map section-------**************** */

.mapped_data p {
  font-weight: 400;
  width: 280px;
  padding: 5px;
  margin: 0 !important;
  margin-left: 5px !important;
}
.parent {
  visibility: hidden;
}
.child {
  overflow-x: scroll;
}
.mapped_data {
  position: relative;
  background: #fff;
  padding: 5px;
  margin: 20px;
  max-width: 400px !important;
  margin: 10px 5px;
  min-width: 300px;
  max-width: 400px;
}
#week_card {
  overflow: hidden !important;
}
.over_scroll {
  overflow-x: scroll;
  padding: 10px;
}
.first {
  display: flex;
  scroll-margin: 10px !important;
}
.mapped_data p {
  font-weight: 500;
  font-size: 14px;
}
.mapped_data p > span {
  font-weight: 400;
}
.first::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  scroll-margin-left: 10px !important;
}
.first::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px #95a28f; */
  padding-left: 20px;
  scroll-padding-left: 20px;
}
.first::-webkit-scrollbar-thumb {
  background-color: #95a28f;
  outline: #95a28f;
  border-right: 20px solid #fff;
  border-left: 20px solid #fff;
  scroll-margin: 20px !important;
}
.approve_icon.red {
  color: #d53343;
}
.content_div {
  list-style: none;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-border-radius: 0.375rem;
  padding: 10px;
}

.box {
  height: 240px;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-border-radius: 0.375rem;
  padding: 10px;
}
.box p {
  font-weight: 500;
  padding: 0px !important;
  margin: 5px !important;
}
.box p span {
  font-weight: 400;
}
.re-btn {
  display: none;
}
.description_icon {
  float: right;
  text-align: center;
  background-color: #768271;
  font-size: 20px;
  width: 35px;
  text-align: center;
  margin: 0 auto;
  color: white;
  border: none;
  outline: none;
}
.main_sec {
  padding: 20px 0;
}

.date-card {
  overflow-x: hidden !important;
  margin: 10px 0;
  height: 200px;
}
.therapy_cont_ > p {
  line-height: 1rem;
}
.date-sec h3 > i {
  font-size: 35px;
  color: #606b5b;
}
.schedule_sub_card {
  /* padding: 10px; */
  min-height: 250px;
  max-height: 350px;
  overflow: hidden;
}
.date-sec {
  width: 100%;
  height: 60px;
  padding: 10px;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 500px) {
  .div-manage {
    overflow-x: scroll;
  }

  .div-manage::-webkit-scrollbar {
    height: 7px;
  }

  .div-manage::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .div-manage::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }


}
@media (max-width: 500px) {
  .sched_btn {
    display: none;
  }
  .re-btn {
    display: block;
    position: absolute;
    outline: none;
    border: none;
    background-color: #8c9886;
    align-items: center;
    overflow: hidden;
    font-weight: 400;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    width: 40px;
    height: 30px;
    border-radius: 5px;
    right: 10px;
    bottom: 10px;
  }
  
  
}

.view-sch {
  border: none;
  background-color: #0072bb;
  color: #fff;
  margin-right: 15px;
  padding: 8px;
  border-radius: 4px;
}
.week-del {
  font-size: 16px;
  border: none;
  color: #fff;
  background-color: #8d9988;
  border-radius: 6px;
  padding: 5px;
}
.week-del:disabled {
  background-color: #aeaeae;
}
.err_ {
  color: #d53343;
  text-align: center;
}
.sch-wek {
  font-weight: 600;
  margin-left: 18px !important;
  padding-bottom: 5px;
}
.ant-modal-footer {
  display: none;
}
.ok {
  border: none;
  padding: 3px;
  margin: 5px;
  background-color: #768271;
  width: 60px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
}
.cancel {
  border: none;
  padding: 3px;
  margin: 5px;
  background-color: #f57a7a;
  width: 60px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
}

@media screen and (max-width:871px) {
  .action_card{
    width: 42% !important; 
  }
  .schedule-head{
    font-size: 27px;
    margin-left: 20px;
  }

  .div-manage{
    margin-top: 29px;
  }
}

@media screen and (max-width:600px) {
  .schedule-head{
    font-size: 23px;
    margin-left: 35px;
  }
  .add-head{
    font-size: 25px;
    margin-top: 10px;
  }
  .duration{
    width: 100%;
  }
  .action_card{
    width: 71% !important; 
  }
}

@media screen and (max-width:490px) {
  .schedule-head{
    font-size: 25px;
    margin-left: 55px;
  }
  
}
