.show-table {
  border-color: #eee !important;
  overflow-x: scroll;
}
.purchase-cont {
  margin-bottom: 30px;
}
.purchase-cont td {
  font-size: 14px;
}
.paging__link--active {
  font-size: 1.2rem;
  font-weight: 700;
  color: #95a28f;
}
._add {
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 6px;
  width: 90px;
  text-align: center;
  float: right;
  margin-top: 30px;
}
.para-tab {
  margin: 2px !important;
  padding: 1px !important;
}
.table-view {
  padding: 15px !important;
  margin-top: 10px;
}
.cur_po {
  cursor: pointer;
}
.sel_sub {
  background-color: #95a28f;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  padding: 0 20px;
  margin-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
}
.sel_sub:hover {
  background-color: #595e56;
}
.sel_sub:disabled {
  background-color: #a0a0a0;
}
.new {
  color: rgb(163, 11, 11);
  background-color: #fcdddd;
}
.type-card {
  font-size: 8px;
  background-color: #249782 !important;
  letter-spacing: 2px;
  font-weight: 400;
  padding: 10px;
  width: 90px;
}
.sch-butn {
  color: green;
  font-size: 18px;
  font-weight: 700;
}
.option-th {
  color: black !important;
}
.res-schedule-sub {
  border: none;
  padding: 10px;
  color: white;
  background-color: rgb(192, 85, 85);
  border-radius: 4px;
  width: 100px;
  margin-top: 10px;
}
.ant-picker {
  border: 1px solid rgb(199, 194, 194) !important;
}
.head-new {
  font-weight: 600;
}
.form-set {
  border: 1px solid rgb(221, 214, 214);
  width: 100px !important;
  text-align: center;
  font-size: 16px;
}
.red-ind {
  color: red;
  cursor: pointer;
}
.badgesadded {
  padding: 5px;
  color: rgb(95, 85, 85);
  background-color: #ccafa7;
  margin: 3px;
  border: none;
  initial-letter-align: 1px;
  width: 100px;
}
.complete {
  font-size: 12px;
  border: none;
  background-color: #95a28f;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 5px;
  position: relative;

  border-radius: 3px;
}
.grnn {
  color: rgb(59, 88, 59);
}
.para-red {
  color: red;
}
.in {
  background-color: #95a28f;
  color: #fff;
  border: none;
  margin: 5px;
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
}
.formSelect {
  border: 1px solid rgb(218, 216, 216);
  border-radius: 4px;
  padding: 4px;
  margin: 3px;
  font-size: 13px;
}
input[type="date"] {
  text-transform: uppercase !important;
}
.refresh_api {
  border: none;
  outline: none;
  background-color: #dbdbdb;
  padding: 5px 10px;
  font-size: 20px;
  border-radius: 5px;
  line-height: 1rem;
}
.refresh_api > span{
  font-size: 15px;
  padding-left: 10px;
}
.refresh_api > i {
  display: inline-block;
  color: #595e56;
  font-size: 15px;
  transition: all 0.2s ease-in-out;
}
.refresh_api:hover > i {
  transform: scale(5);
  transform: rotate(90deg);
}
.refresh_api:active > i {
  transform: rotate(360deg);
}
