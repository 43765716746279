.img_btn {
  cursor: pointer;
  color: #95a28f;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
}

.joint {
  display: flex;
}

.main {
  height: 500px !important;
}

.program_table {
  padding: 10px;
  width: 100%;
  margin: 30px;
  margin-right: 30px;
}

.instn_card {
  overflow: hidden;
  border-color: #e2e5e0;
}

.instn_card::-webkit-scrollbar {
  height: 7px;
}

.instn_card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.instn_card::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.check {
  margin-left: 10px !important;
}

.wid {
  background-color: #d9534f;
  z-index: 1;
  color: #ffffff;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.2s linear;
  margin-bottom: 15px;
  margin-right: 10px;
  text-align: center;
  height: 40px;
  font-size: 20px;
  overflow: hidden;
}

.therapy_tbl {
  width: 100% !important;
}

i {
  /* align-items: center;
    text-align: center; */
  margin: 0px auto;
}

#cat_card {
  overflow-x: scroll !important;
  overflow-y: hidden;
  width: 100%;
}

.add_file {
  border-color: #c4d4bc !important;
  box-shadow: none !important;
  outline: 0 none !important;
}

.category_add {
  height: 550px;
  margin: 0px auto;
}

.category_add.show {
  display: block;
}

.cat.show {
  display: block;
}

.btsuc {
  margin: 5px !important;
  background-color: #198754 !important;
  height: 30px;
  width: 30px;
  border: none;
  outline: none;
  color: #020202;
  padding: 10 !important;
  border-radius: 3px;
}

/* modal */
.modal_content {
  width: 100% !important;
}

.modal_wrapper {
  position: fixed;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(100%) blur(2px);
  z-index: 100;
}

.modal_wrapper > .modal_container {
  position: absolute;
  background-color: #fff;
  padding: 20px 30px;
  left: 550%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: none;
  transition: all 0.5s ease-in-out;
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 1s;
  -o-animation: fadein 2s;
  animation: fadein 1s;
}

.edit-form {
  width: 60%;
  /* margin-top: 30px !important; */
  margin: 0px auto;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.form_btn .modal_wrapper > {
  min-width: 100%;
  min-height: 500px !important;
  position: relative;
  overflow: none;
}

.modal_container > .close {
  border: none;
  outline: none;
  background-color: transparent;
  float: right;
  color: #252525;
  cursor: pointer;
}

.image_ {
  font-size: 25px;
  color: #95a28f;
  text-align: center;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

.image_:hover {
  color: #7c8777;
}

.therapy_table {
  border-color: #eee !important;
}

.therapy_img_area {
  width: 80px;
  height: 80px;
  background-color: transparent;
  margin: 0px auto;
  border-radius: 5px;
  overflow: hidden;
}

.therapy_img_area > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* styles for pagination */
.pagination {
  display: flex;
  justify-content: center;
  letter-spacing: 10px;
  padding: 10px;
  margin: 10px;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
  margin: 0px auto;
  margin-bottom: 50px;
}

.star_div {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #ababab;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.star_div.active {
  color: #ffd700;
}

.therapy_img {
  min-width: 130px;
}

@media screen and (max-width: 991px) {
  .modal_content {
    width: auto;
  }
}

@media screen and (max-width: 786px) {
  .category_add {
    height: 550px;
    width: 67% !important;
    margin-left: 20px !important;
    font-size: 12px !important;
  }

  .modal_content {
    width: auto;
  }

  .form-select {
    font-size: 14px;
  }
  .profile-div {
    margin-left: 50px;
  }
}

@media screen and (max-width: 600px) {
  .category_add {
    width: 71% !important;
    margin-left: 35px !important;
  }

  .form-select {
    font-size: 14px;
  }

  .add_file {
    font-size: 14px;
  }
  .modal_content {
    width: auto !important;
  }
}

.react-dialogue {
  text-align: center;
  margin: 0px auto !important;
}

.edit-therapy-image {
  height: 100px;
}

.confirmation-content {
  margin: 0px auto;
  text-align: center;
  margin: 10px;
  padding: 10px;
}

.dialo-box {
  margin: 0px auto !important;
  text-align: center !important;
}

/* ------Edit Therapy---------- */
.edit-therapy {
  position: relative;
  /* height: 100px;
  width: 100px; */
  background-color: #eee;
  margin: 0px auto;
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 16/9;
  margin-bottom: 20px;
}

.edit-therapy > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  border-radius: 50%;
  outline: none;
  width: 25px;
  height: 25px;
  padding: 0px 5px;
}

.close-btn > i {
  font-size: 13px;
}

.therapy_img_file {
  display: none;
}
.edit-therapy-img {
  cursor: pointer;
}

@media screen and (max-width: 790px) {
  .edit_btn {
    width: 47%;
  }
}

@media screen and (max-width: 580px) {
  .edit_btn {
    width: 50%;
    font-size: 19px;
  }

  .edit_therapy {
    margin-left: 28px;
    width: 100%;
  }
  .therapy-head {
    font-size: 29px;
    margin-left: 33px;
    margin-top: 7px;
  }
}
