.user_card {
  border-color: #eee !important;
  overflow-x: scroll;
}
.user_card::-webkit-scrollbar {
  height: 7px;
}

.user_card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.user_card::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.res {
  background-color: rgb(247, 209, 209) !important;
  color: red !important;
}
.edit-user {
  color: green;
  background-color: transparent;
  border: none;
}
.pagnte {
  margin-top: 30px !important;
  background-color: #fff;
  margin: 0px auto;
  width: 150px;
  border: none;
  box-shadow: 1px 1px #f1ebeb;
}
.pagnte .page-link {
  border: none;
}
.Add {
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 6px;
  width: 90px;
  text-align: center;
  float: right;
  margin-right: 5%;
  margin-top: 30px;
}
.add-bgrd {
  width: 100%;
}
.bac {
  width: 100%;
  text-align: center;
  margin: 10px;
  padding: 50px;
}
.logo-main {
  text-align: center;
  padding: 10px;
  width: 120px;
}
.client-form {
  width: 90%;
}
.add-c {
  color: gray;
}
.err_msg {
  color: red;
}

@media screen and (max-width: 780px) {
  .edit-client {
    padding-top: 58px;
  }
  .form_btn {
    font-size: 18px;
    width: 42%;
    height: 43px;
  }
  .add-client {
    padding-left: 30px;
  }
}
