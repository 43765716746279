.live-streaming {
  min-height: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0;
  height: auto;
  background-color: #fff;
  margin-top: 20px;
}
.button-back {
  font-size: 16px;
  color: gray;
  box-shadow: 0 0 0 transparent;
  margin-bottom: 20px;
  width: 50px;
  height: 40px;
  border-radius: 7px;
  border: none;
}
.des {
  font-size: 14px;
  text-align: center;
}
.record-btn {
  color: red;
  margin-right: 8px;
}
.record {
  color: gray;
  font-size: 15px;
  text-align: left;
  float: left;
}
.add-pel {
  color: rgb(40, 173, 40);
  font-size: 15px;
  margin-right: 8px;
}
.add {
  color: rgb(53, 217, 53);
  float: right;
  text-align: right;
}
.class_screen {
  position: relative;
  aspect-ratio: 16/9;
  padding: 0px;
  background-repeat: no-repeat;
  border-radius: 10px;
  overflow: hidden;
  background-image: url("../../assets/nest_placeholder.jpg");
  background-size: cover;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: right;
}
.live-one {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 13px;
}
.session-studn {
  width: 78px;
  height: 77px;
  border-radius: 9px;
  border: 2px solid white;
}
.mute {
  color: white;
  font-size: 11px;
}
.respon {
  margin-top: -10px;
  float: right;
  position: relative;
  background-color: red;
  border-radius: 50%;
  height: 18px;
  text-align: center;
  width: 18px;
}
.unmute {
  color: white;
  background-color: rgb(56, 214, 56);
  font-size: 11px;
  margin-top: -10px;
}
.listner {
  position: absolute;
  background-color: rgba(19, 19, 19, 0.507);
  width: 70px;
  height: 35px;
  border-radius: 10px;
  text-align: center;
  margin-left: 20px;
  margin-top: 20px;
  z-index: 99999;
}
.listner > p {
  color: #fff;
  margin-top: 5px;
}
.name {
  color: white;
  opacity: 100% !important;
}
.controllers {
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 10px;
}
.cntrl_btn {
  display: block;
}
.control-btn {
  position: relative;
  color: gray;
  border: 1px solid rgb(241, 236, 236);
  width: 60px;
  height: 60px;
  padding: 5px;
  border-radius: 50%;
  font-size: 20px;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  margin: 0 10px;
}
.control-btn:hover {
  background-color: #eee;
}
.hungup {
  width: 70px;
  height: 70px;
  color: white;
  background-color: #dc3545;
  margin-left: 10px;
}
.hungup:hover {
  background-color: #ed0a3f;
}
.controller_btn_ico {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.active-button {
  background-color: #dc3545;
  color: #fff;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.active-class {
  background-color: #249782;
  color: #fff;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.controller-label {
  /* width: 50%; */
  display: block;
  font-size: 13px;
  margin-top: 15px;
  white-space: nowrap;
  color: #a9a9a9;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;
  text-align: center;
}
.control-btn:hover .controller-label {
  opacity: 1;
}
.classCard {
  display: flex;
  width: 150px;
  height: 30px;
  background-color: #e8eee5;
  border-radius: 20px;
  margin-left: 10px;
  cursor: pointer;
  padding: 0 10px;
}
#class_info {
  color: #95a28f;
  margin: auto;
}
.classCard > p {
  font-size: 13px;
  margin: auto;
  margin-left: 0;
}
.head-title_ {
  user-select: none;
  white-space: nowrap;
  max-width: 450px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nav-tabs {
  --bs-nav-tabs-border-color: none;
}
.tab_sec {
  background-color: #fff;
  border-radius: 25px;
  border: 2px solid white;
  border: none;
  outline: none;
  width: 98%;
}
.tab {
  border: none;
  outline: none;
  box-shadow: none !important;
}
.nav-link {
  color: #95a28f;
  background-color: white;
  /* font-weight: 500; */
  font-size: 15px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #e9f5f3;
  color: #249782;
}
.nav-link:hover {
  color: #249782;
  background-color: #e9f5f3;
}
.sent-box {
  display: flex;
  z-index: 99;
  border: none;
  background-color: #fff;
  /* padding-bottom: 20px; */
  margin: 0px auto;
  /* margin-top: 20px !important; */
  text-align: center;
  border: 1px solid #a5a5a577;
}
.sent-box input {
  outline: none;
  height: 47px;
  border-radius: 10px;
  width: 100%;
  font-size: 15px;
  margin: 0px auto;
  border: none;
}
.file_button {
  width: 50px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  padding: 0 10px;
}
.send_ {
  width: 50px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  padding: 0 10px;
  background-color: #249782;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.send_:hover {
  background-color: #197161;
}
.card-mo {
  min-height: 650px;
}
.chat-right {
  width: 100%;
  float: right;
}
.chat-right > .txt {
  margin-left: auto;
  margin-right: 3px;
}
.type_area {
  margin-bottom: 0;
  width: 80%;
  height: 63px !important;
  overflow: hidden;
  height: 50px;
  width: 100%;
}
.nav-tabs .nav-link {
  border-radius: 5px;
  /* padding: 10px;
  margin: 10px; */
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 86% !important;
  margin-left: 10px;
  height: 45px;
}
/* live-session--------------------------- */

.tab {
  border: none;
  outline: none;
  box-shadow: none !important;
}
.nav-link {
  color: #95a28f;
  background-color: white;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #e9f5f3;
  color: #249782;
}
.nav-tabs .nav-link {
  border-radius: 5px;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 86% !important;
  margin-left: 10px;
}

/* -------------imported from old design--------------- */
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
.stream_page {
  margin: 0;
  min-height: 100%;
  height: auto;
  padding: 0 30px;
}
.stream_head {
  width: 100%;
  height: 100px;
  background-color: transparent;
  margin: 0;
  padding: 15px;
  border-radius: 10px;
  position: relative;
}

.class_head {
  display: grid;
  grid-template-columns: 0.2fr 0.2fr;
  padding: 0;
  font-family: "Allura", cursive;
}
.stream_logo {
  width: 150px;
  height: 150px;
  overflow: hidden;
  text-align: center;
  margin: 0px auto;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.stream_logo > img {
  max-width: 100%;
}
.stream_title {
  padding: 10px;
}
.head_title {
  width: 500px;
  height: 30px;
  white-space: nowrap;
  font-size: 28px;
  cursor: pointer;
  color: var(--secondary-color);
  transition: all 0.5s ease-in-out;
}
.stream_title p > span {
  font-weight: 600;
  color: var(--third-color);
}
.btn_grp {
  width: 300px;
  padding: 10px;
  float: right;
}
.clss_btn {
  width: 70px;
  height: 50px;
  padding: 10px 12px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgb(200, 200, 200);
  color: #545353;
  margin: 0 5px;
  text-decoration: none;
  user-select: none;
  font-size: 15px;
}
.clss_btn:hover {
  color: #252525 !important;
}
.clss_btn:active {
  box-shadow: 0 2px 2px rgb(199, 199, 199);
}
.exit_class {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 5px rgb(200, 200, 200);
  margin: 0 5px;
  font-size: 15px;
}
.exit_class:hover {
  background-color: #ff2e2e;
  color: #fff;
}
.screen {
  position: relative;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 10px;
  overflow: hidden;
}
.screen > img {
  max-width: 100%;
}
.screen > iframe {
  min-width: 100%;
  min-height: 100%;
}

.screen > .video-player {
  position: relative;
  max-width: 100% !important;
  height: auto !important;
}
.chat_main {
  position: relative;
  width: 95%;
  margin-top: 40px;
  height: 100%;
  max-height: 770px;
  /* background-color: #f5f7fb; */
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  aspect-ratio: 16/9;
}
.tab-div {
  background-color: #fff;
  height: 70px;
  width: 88%;
  margin-top: 9px !important;
  border-radius: 12px;
}
/* .chat_head {
  width: 100% !important;
  display: flex;
  position: relative;
  padding: 0 !important;
} */
.chat_head > h2 {
  color: var(--secondary-color);
}
.chat_head > p {
  color: #b2b2b4;
  padding: 10px;
}
.chat_head > button {
  position: absolute;
  right: 20px;
  top: 20px;
  color: var(--secondary-color);
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
}
.chat_head > button:active {
  background-color: rgb(208, 208, 208);
}
.chat_area {
  padding: 10px;
  min-height: 520px;
  width: 100%;
}
.chat_info {
  position: relative;
  width: 100%;
  height: 80px;
  background-color: #249782;
  color: white;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  font-size: 12px;
  margin-top: 4px;
  font-weight: 400 !important;
}
.chat_info > h5 {
  color: white;
  font-size: 12px;
  text-align: center;
}
.chat_info > h6 {
  font-size: 12px;
  padding: 0 10px;
  color: white;
  text-align: center;
}
.chat_info > i {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
}
.chat {
  position: relative;
  width: 100%;
  padding: 5px 0px;
  margin-left: 10px;
}
.chat_area_left {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #f5f7fb;
  min-height: 500px;
  max-height: 550px;
}
.chat_area_left::-webkit-scrollbar {
  width: 7px;
  height: 7px !important;
}
.chat_area_left::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f5f7fb;
}
.chat_area_left::-webkit-scrollbar-thumb {
  background-color: #c4d4bc;
  outline: #95a28f;
}
.clear_chat {
  position: absolute;
  font-size: 22px;
  background-color: #ffff;
  padding: 5px 10px;
  border-radius: 50%;
  color: #8b8b8b;
  right: 10px;
  top: 100px;
  transition: all 0.2s ease-in-out;
  z-index: 10;
  border: none;
  outline: none;
  box-shadow: 0 2px 2px #dddcdc;
}
.clear_chat:hover {
  background-color: #f8f5f5;
}
.avt {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f2f3f7;
  padding: 3px;
  text-align: center;
}
.avatarpng {
  width: 40px;
  height: 40px;
}
.avt > img {
  max-height: 100%;
}
.txt > .txt_cont {
  width: auto;
  color: black;
  padding: 12px 20px;
  background-color: #fff;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;

  font-weight: 500;
}
.chat .txt > .txt_cont {
  border-radius: 0px 12px 12px 12px;
}
.chat-right .txt > .txt_cont {
  border-radius: 12px 12px 0px 12px;
}
.txt > .txt_conts {
  width: auto;
  color: black;
  padding: 12px 20px;
  background-color: #e4e8f1;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  border-radius: 12px 12px 0px 12px;
  font-weight: 500;
}
#right {
  margin: 0 10px;
}
.type_area {
  margin-bottom: 0;
  width: 80%;
  overflow: hidden;
  height: 50px;
  width: 100%;
}
hr {
  margin: 0;
}

.info_alert {
  position: absolute;
  width: auto;
  height: 40px;
  padding: 10px 20px;
  background-color: #25252590;
  color: #fff;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  text-align: center;
  border-radius: 20px;
  animation: appear 0.5s;
}
.info_alert p {
  font-size: 13px;
  padding: 0 20px;
}

/* Info Modal Styling */
.modal_head {
  font-size: 15px;
  color: #252525;
  margin-top: 10px;
  margin-bottom: 3px;
}
.modal_names {
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.modal_sub {
  font-size: 13px;
  color: #747474;
  letter-spacing: 0.1em;
  margin-top: 0;
}
.therapist_profile {
  width: 120px;
  height: 120px;
  background-color: #252525;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 50%;
  overflow: hidden;
}
.therapist_profile > img {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}

/* Settings Modal */
.settings-screen {
  width: 220px;
  height: 120px;
  background-color: #000;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  overflow: hidden;
}
.settings-screen > img {
  max-width: 100%;
  min-height: 100%;
}
.settings-select {
  width: 100%;
  height: 30px;
  border: 4px;
  border: 1px solid #eee;
  font-family: "FontAwesome", "Raleway", sans-serif;
  outline: none;
}
.ant-modal.css-dev-only-do-not-override-1km3mtt {
  width: 600px !important;
}

.camera-settings-ico {
  color: #747474;
  margin: 10px;
  font-size: 15px;
}
.dropdown-menu {
  width: 100%;
}
.dropdown-item:active {
  background-color: #95a28f;
  color: #fff;
}

.set_btn {
  padding: 0 10px;
  height: 30px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.set_btn.ok {
  width: 120px;
  background-color: #249782;
  color: #fff;
}
.set_btn.cancel {
  width: 100px;
}
.set_btn.ok:hover {
  background-color: #197161;
}
.set_btn.cancel:hover {
  background-color: #dc3545;
  color: #fff;
}

.player-2 {
  position: relative;
  width: 250px;
  background-color: #252525;
  position: absolute !important;
  right: 10px;
  top: 10px;
  border-radius: 10px;
  z-index: 9999;
  overflow: hidden;
}
.player-2::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(37, 37, 37, 0.588);
  z-index: 9999;
}
.part_card {
  text-align: center;
  margin-bottom: 20px;
  margin-right: 10px !important;
  aspect-ratio: 16/9;
  overflow: hidden;
  border: none;
}
.pin_btn {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
  border: none;
  outline: none;
}
.part_card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000004c;
  transition: all 0.2s ease-in-out;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
}

.part_card:hover::before {
  opacity: 1;
}
.part_card:hover > .pin_btn {
  opacity: 1;
}
.cont .btn-cont {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  color: #249782;
  border-radius: 50%;
  font-size: 13px;
  width: 22px;
  height: 22px;
  border: none;
  outline: none;
}
.scroll-area {
  height: 650px;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.cont {
  aspect-ratio: 16/9;
  overflow: hidden;
}
.scroll-area::-webkit-scrollbar {
  width: 7px;
  height: 7px !important;
}
.scroll-area::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f5f7fb;
}
.scroll-area::-webkit-scrollbar-thumb {
  background-color: #c4d4bc;
  outline: #95a28f;
}
.name-part {
  margin-top: 50px;
}

/* Hover on Parent Container */
.paragra {
  margin-top: 25%;
}
.ant-modal.css-dev-only-do-not-override-acm2ia {
  width: 600px !important;
}
@keyframes appear {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 50px;
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1200px) {
  .part_card,
  .chat_info {
    margin-top: 20px;
  }

  .control-btn {
    height: 45px;
    width: 45px;
    font-size: 15px;
  }
  .controller-label {
    font-size: 12px;
    margin-top: 5px;
  }
  .hungup {
    height: 55px;
    width: 55px;
  }
  ul li {
    padding: 15px 0 5px 0;
    margin-top: 0 !important;
  }
  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link {
    width: 86% !important;
    height: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .modal-ab {
    width: 50%;
    padding: 10px;
    margin: 0;
    position: relative;
    right: 0;
    float: right;
    top: 21%;
    height: 300px;
  }
  .tab-div {
    background-color: white;
    width: 88%;
    margin-top: 9px !important;
    margin-bottom: 10px !important;
    border-radius: 12px;
    height: 14vh;
  }
  .chat_main {
    position: relative;
    width: 100%;
    margin-top: 40px;
    height: 700px;
    /* background-color: #f5f7fb; */
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
  }
  .sent-box {
    z-index: 99;
    border: none;
    margin: 0px auto;
    margin-top: 0px !important;
    text-align: center;
  }
  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 786px) {
  .tab-div {
    background-color: white;
    width: 100%;
    margin: 0 !important;
    border-radius: 12px;
    height: 10vh;
  }
  .clear_chat {
    right: 10px;
    box-shadow: 0 2px 2px #7777;
  }
}
@media screen and (max-width: 600px) {
  .stream_page {
    padding: 0;
  }
}
.part_card {
  text-align: center;
  margin-bottom: 20px;
  margin-right: 10px !important;
  aspect-ratio: 16/9;
}
.cont .btn-cont {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  color: #249782;
  border-radius: 50%;
  font-size: 13px;
  width: 22px;
  height: 22px;
}
.scroll-area {
  height: 650px;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.cont {
  aspect-ratio: 16/9;
  background-size: cover;
}
.scroll-area::-webkit-scrollbar {
  width: 7px;
  height: 7px !important;
}
.scroll-area::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f5f7fb;
}
.scroll-area::-webkit-scrollbar-thumb {
  background-color: #c4d4bc;
  outline: #95a28f;
}
.name-part {
  margin-top: 50px;
}

/* Hover on Parent Container */
.cont:hover {
  cursor: pointer;
}
/* 
.cont:hover div {
  height: 100%;
  padding: 8px 15px;
  visibility: visible;
  opacity: 0.6;
}
.paragra {
  margin-top: 25%;
} */

@media screen and (max-width: 1170px) {
  .tab-div {
    height: 140px;
  }
  .nav-item {
    margin-top: 10px;
  }
}
.modal-ab {
  position: absolute;
  bottom: 13%;
  margin-left: 30%;
  z-index: 999;
  width: 30%;
  height: 400px;
  overflow-y: scroll;
  border-radius: 4px !important;
}
.msg-in {
  width: 100%;
  border: 1px solid gray;
  height: 100px;
  border-radius: 4px;
  padding: 5px !important;
  margin: 10px;
}
.modal-al-sto {
  padding: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
  height: 670px;
  z-index: 999;
  overflow-y: scroll;
}

.send-bb {
  background-color: #249782;
  height: 40px;
  top: 10px;
  width: 100px;
  margin: 0px auto;
  border: none;
  padding: 10px;
  position: relative;
  color: white;
  border-radius: 4px;
}
.older-msg {
  padding: 10px;
}
.older-para {
  overflow: hidden;
  border-radius: 2px;
  padding: 5px;
  /* text-align: justify; */
  font-size: 13px;
  border: 1px solid rgb(221, 215, 215);
}
.modal-al-sto::-webkit-scrollbar {
  height: 3px;
  width: 2px;
}

.modal-al-sto::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(223, 223, 223, 0.3);
}

.modal-al-sto::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  outline: 1px solid slategrey;
}
.trash {
  cursor: pointer;
  color: red;
  border: none;
  background-color: transparent;
  margin-top: 5px;
}
.settings-bar {
  border: none;
  background-color: transparent;
  padding: 5px;
  padding-right: 10px;
  color: #249782;
  font-size: 20px;
}
.close {
  color: red;
  border: none;
  background-color: transparent;
}
.notted-dat {
  width: 100% !important;
  padding: 5px;
  border-radius: 4px;
}
.note-head {
  color: #197161;
}
.shared_file {
  width: 200px;
  background-color: #f7f7f7;
  padding: 10px 0;
  margin-left: 10px;
  border-radius: 10px;
  border: 1px solid #eee;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.shared_file a {
  text-decoration: none;
  color: #252525;
  font-size: 13px;
}
.shared_file a > i {
  padding: 10px 5px 0 10px;
  color: #252525;
  font-size: 20px;
}
.uploading {
  position: absolute;
  bottom: 10px;
  left: 40%;
  transform: translateX(-50%);
  font-size: 13px;
  background-color: #54535337;
  padding: 5px 20px;
  border-radius: 16px;
  user-select: none;
}
/* .progress-bar {
  position: relative;
  height: 2px;
  background-color: #249782;
  transition: all 0.2s ease-in-out;
}
.progress-bar.done {
  width: 0px;
}
.progress-bar.half {
  width: 50%;
}
.progress-bar.full {
  width: 100%;
} */
.progress {
  height: 10px;
  border-radius: 0px;
}
.file_selected {
  position: absolute;
  max-width: 70%;
  padding: 5px 25px 5px 10px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d4d4d4;
  border-radius: 16px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 49;
}
.msg-content:disabled {
  background-color: #dedede;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dwnld {
  margin-left: 10px;
  color: #197161;
  position: relative;
  top: 3px;
  font-size: 16px;
}
.msg-content::placeholder {
  color: #252525;
}
.file_selected > i {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.file_selected {
  position: absolute;
  max-width: 70%;
  padding: 5px 25px 5px 10px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d4d4d4;
  border-radius: 16px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 49;
}
.file_selected > i {
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.guest-btn {
  border: none;
  border-radius: 4px;
  background-color: #c4d4bc;
  color: black;
  padding: 5px;
  font-weight: 500;
  width: 100px;
  text-transform: uppercase;
  font-size: 16px;
}
