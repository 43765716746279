:root {
  --red: #879580;
  --darkred: #95a28f;
  --platinum: #e5e5e5;
  --black: #2b2d42;
  --white: #fff;
  --thumb: #edf2f4;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container-class {
  max-width: 1400px;
  padding: 0 15px;
  margin: 0 auto;
}

.headtwo {
  font-size: 32px;
  margin-bottom: 1em;
}

.cards-data {
  display: flex;
  padding: 25px 0px;
  list-style: none;
  overflow-x: scroll;
  transform: rotateX(180deg);
  scroll-snap-type: x mandatory;
}
.card-content span {
  font-weight: 400;
}
.card-content p {
  font-weight: 500;
  padding: 0;
  margin: 5px;
  font-size: 15px;
}

.card-data {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  border-radius: 5px;
  border: 1px solid #d8ecce;
  scroll-snap-align: start;
  transition: all 0.2s;
  overflow-y: scroll;
  height: auto;
  transform: rotateX(180deg);
  padding-left: 7px;
}
.card-data::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.card-data::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #95a28f00;
}
.card-data::-webkit-scrollbar-thumb {
  background-color: #95a28f;
  outline: #95a28f;
}
.sprd-data {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 15%);
  padding: 10px;
  background-color: white;
  height: auto;
  width: 250px !important;
}

.card-data .card-title {
  font-size: 20px;
}

.card-data .card-content {
  margin: 5px 0;
  max-width: 100%;
}

.card-data .card-link-wrapper {
  margin-top: auto;
}

.card-data .card-link {
  display: inline-block;
  text-decoration: none;
  color: white;
  background: #879580;
  padding: 6px 12px;
  border-radius: 8px;
  transition: background 0.2s;
}
.cards-data::-webkit-scrollbar {
  height: 10px;
  margin-bottom: 20px !important;
}
.approve {
  background-color: #768271;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  color: white;
  width: 25px;
  border: none;
  margin-bottom: 5px;
  outline: none;
}
.delete-sch {
  background-color: #768271;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  color: white;
  width: 34px;
  height: 35px;
  border: none;
  float: right !important;
  border-radius: 4px;
  outline: none;
}
.cards-data::-webkit-scrollbar-thumb,
.cards-data::-webkit-scrollbar-track {
  border-radius: 92px;
  padding-bottom: 20px;
}

.cards-data::-webkit-scrollbar-thumb {
  background: #95a28f;
}

.cards-data::-webkit-scrollbar-track {
  background: var(--thumb);
}

@media (min-width: 500px) {
  .card-data {
    flex-basis: calc(50% - 10px);
    font-size: 10px;
  }

  .card-data:not(:last-child) {
    margin-right: 20px;
  }
  
}

@media (min-width: 700px) {
  .card-data {
    flex-basis: calc(calc(100% / 3) - 20px);
    font-size: 10px;
  }

  .card-data:not(:last-child) {
    margin-right: 30px;
  }
}

@media (min-width: 1100px) {
  .card-data {
    flex-basis: calc(25% - 30px);
    font-size: 12px;
  }

  .card-data:not(:last-child) {
    margin-right: 10px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width:644px) {
  .headtwo{
    padding-left: 80px;
    font-size: 28px;
    margin-bottom: 10px;
  }  
}

.week_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 10px 0 20px 0;
  font-size: 25px;
  overflow: hidden;
  text-align: center;
  z-index: 10;
  background-color: #f9f9f9;
}
.approve_btn {
  background-color: #768271 !important;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}
#err {
  color: #ff0000;
}
#date-pending-list {
  min-height: 250px;
  padding: 20px;
}
#date-pending-list > h1 {
  font-size: 25px;
  line-height: 1.5rem;
  margin-bottom: 0.8rem;
}
#date-pending-list p > span {
  font-size: 13px;
}
.date-cont {
  line-height: 1rem;
  margin-bottom: 7px;
  font-size: 15px;
}
#date-desc {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#date-approve {
  position: absolute;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  right: 10px;
  font-size: 20px;
  bottom: 10px;
}
.sch-wek {
  font-weight: 600;
  margin-left: 18px !important;
}
