.react-tabs {
  display: flex;
  width: 900px;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 50px;
  margin-left: 60px;
  padding: 0;
  border-bottom: none !important;
  border-right: 3px solid #fff;
}
.new-set {
  background-color: #fff;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  font-weight: 600;
  width: 300px;
  text-align: center;
}
.icon-div i {
  font-size: 23px;
  position: relative;
  top: -7px;
}
.icons-div {
  font-size: 30px;
  border-radius: 50%;
  align-items: center;
  margin-left: 10px;
  justify-items: center;
  background-color: #fff;
}

.react-tabs__tab {
  height: 50px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #bbb;
  width: 100%;
}

.react-tabs__tab--selected {
  border: none !important;
  background-color: #c5d5bd !important;
  border-radius: 0px !important;
  height: 50px;
  outline: none;
  padding: 10px 0;
}

.admin_pro {
  margin-left: 4% !important;
  font-size: 25px;
}

.admin_pro1 {
  text-align: center;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  width: 100%;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  width: 100%;
  padding: 0 20px;
  text-align: left;
}

.profile_card {
  width: 100%;
  overflow: hidden !important;
  padding: 20px;
}

.profile_pic {
  display: block;
  margin: 0 auto;
  width: 120px;
  height: 120px;
  background-color: #eee;
  border-radius: 50%;
}

.panel-content {
  overflow: hidden;
}

.set_btn {
  margin-left: 15px;
  font-weight: 500;
  font-size: 17px;
  color: black;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #c5d5bd;
}

.admin-btn {
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: 500;
  padding: 8px;
  padding-left: 19px;
  padding-right: 19px;
  background-color: transparent;
}

.admin-btn:hover {
  background-color: #fcfafa;
}

.card-btn {
  column-gap: 5px;
  margin-top: 51px;
  justify-content: center;
}

@media screen and (max-width: 874px) {
  .card-btn {
    flex-direction: column;
    gap: 17px;
  }

  .btn-setting {
    margin: 0px auto;
    width: 70%;
  }

  .admin-btn {
    font-size: 18px;
  }

  .admin-heading {
    font-size: 28px;
  }
}

@media screen and (max-width: 500px) {
  .admin-btn {
    font-size: 17px;
  }
  .admin-heading {
    font-size: 25px;
  }
}

/* @media screen and (max-width: 766px){
  
  .card{
    margin-right: 100px !important;
    overflow: hidden;
  }
  .panel-content{
    width: 60%;
    height: 700px;
  }

}
@media screen and (max-width: 686px){
  .react-tabs__tab-list{
    width: 90px;
  }
  .card{
    margin-right: 100px !important;
  }
  .panel-content{
    width: 50%;
  }

}
@media screen and (max-width: 606px){
  .panel-content{
    width: 40%;
  }
  .react-tabs__tab-list{
    width: 70px;
  }
  .card{
    margin-right: 100px !important;
    overflow: hidden;
  }

}
@media screen and (max-width: 491px){
  .panel-content{
    width: 32%;
  }
  .react-tabs__tab-list{
    width: 70px;
  }
  .card{
    margin-right: 100px !important;
    overflow: hidden;
  }
} */
.naviagte-menu {
  /* padding: 10px 20px; */
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background-color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  color: #66795d;
  text-align: left;
  border: none;
}
.navigate-btns {
  display: block !important;
}

.naviagte-menu::after {
  content: "\2192";
  display: inline-block;
  margin-left: 10px;
  opacity: 0;
  font-size: 30px;
  transform: translateX(-10px);
  transition: transform 0.3s, opacity 0.3s;
}

.naviagte-menu:hover::after {
  transform: translateX(0);
  opacity: 1;
  font-size: 30px;
}
