.audioRate {
  width: 8px;
  height: calc(100% - 40px);
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  background-image: linear-gradient(red 10%, yellow 20%, green 50%);
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  clip-path: inset(100% 0 0 0);
}

.cameraContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.camMicSelectorContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 30px;
}

.noDeviceBadge {
  margin-top: 10px;
  padding: 10px;
}

.camVideo {
  width: 100%;
  height: 100%;
  position: relative;
  background: black;
  padding: 0;
}

.deviceDropdown {
  float: right;
  width: 100%;
  margin-bottom: 1em;
  margin-top: 0.2em;
}

.widgetClose {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #38c485;
  z-index: 99;
  font-size: 24px;
}

.deviceIcon {
  font-size: 21px;
  margin-bottom: 5px;
}

.actionButton {
  width: 200px;
}

.modalHeading {
  justify-content: center;
  font-weight: bold;
}

.modalHeading > h5 {
  font-size: 20px;
}

@media (max-width: 480px) {
  .buttonContainer {
    display: flex;
    flex-direction: column;
  }
}

.buttonContainer .btn-dark {
  border-color: #7e7e7e !important;
}
.guest-section {
  margin-top: 100px;
  border: 1px solid black;
}
.security {
  border: 1px solid rgb(199, 198, 198);
  width: 58px !important;
  margin-bottom: 20px !important;
  padding: 5px;
  margin: 5px;
  border-radius: 4px;
}
.part {
  padding-top: 5%;
}
.security_Cont {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../assets/instructors/5228369.jpg");
  background-size: cover;
}
@media screen and (max-width: 648px) {
  .security {
    width: 40px !important;
  }
  .part {
    padding-top: 20%;
  }
}
